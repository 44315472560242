<template>
    <div class="transparent-tabs">
        <sticky-with-intersection :background-sticky-color-classes="$store.getters['ColorManager/getSystemAppBarColorForOtherElements']">
            <v-alert
                dense
                text
                type="info"
                :icon="false"
                v-if="saving"
            >
                <v-icon small>$spinner</v-icon>
                - Dein Abo wird gerade gespeichert
            </v-alert>

            <v-alert
                dense
                type="error"
                dismissible
                v-if="alert.visible"
                v-html="alert.content"
            />

            <div class="w-full">
                <text-field-with-validation
                    label="Name"
                    v-model="form.name"
                    prepend-icon="$formName"/>
            </div>

            <v-tabs v-model="tab" class="pb-3">
                <v-tab key="plex">
                    Plex
                </v-tab>
                <v-tab key="media">
                    Medien
                </v-tab>
            </v-tabs>
        </sticky-with-intersection>

        <v-tabs-items v-model="tab">
            <v-tab-item key="plex">
                <plex-search-core
                    ref="searchCorePlex"
                    :name="form.name"
                    :dont-trigger-search="dontTriggerSearch"
                    :use-default-select-event="false"
                    force-search-without-details
                    :result-filter-list="preFiltered"
                    v-on:plex-search:result-selected="resultSelected"
                    v-on:plex-search:results-reset="plexAbos = {}"
                    v-on:plex-search:results-loaded="plexAbos = $event"
                />
            </v-tab-item>

            <v-tab-item key="media">
                <media-search-core
                    ref="searchCore"
                    :year="form.year"
                    :name="form.name"
                    :dont-trigger-search="dontTriggerSearch"
                    :use-default-select-event="false"
                    force-search-without-details
                    :result-filter-list="preFilteredForMedia"
                    v-on:media-search:result-selected="resultSelected"
                    v-on:media-search:providers-loaded="loadedApiProviders"
                />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import MediaSearchCore          from '@symfia/media/views/MediaSearch/MediaSearchCore';
    import PlexSearchCore           from '@symfia/plex/views/PlexSearch/PlexSearchCore';
    import StickyWithIntersection   from '@symfia/library/Views/Helper/StickyWithIntersection';
    import TextFieldWithValidation  from '@symfia/core/components/VeeValidate/TextFieldWithValidation';
    import YearPickerWithValidation from '@symfia/core/components/VeeValidate/YearPickerWithValidation';

    export default {
        components: {
            MediaSearchCore,
            StickyWithIntersection,
            TextFieldWithValidation,
            YearPickerWithValidation,
            PlexSearchCore,
        },

        props: {
            noRedirect: {
                type:    Boolean,
                default: false,
            },

            comesFromRoute: {
                type:    String,
                default: '/plex-abos',
            },

            preFiltered: {
                type:    Array,
                default: null,
            },
        },

        watch: {},

        beforeDestroy() {
            this.abortRequests();
        },

        data: () => ({
            dontTriggerSearch: false,
            plexAbos:          {},
            saving:            false,
            axios:             null,
            alert:             {
                visible: false,
                content: '',
            },
            tab:               'plex',
            form:              {
                year: '',
                name: '',
            },
        }),

        methods: {
            abortRequests() {
                if (this.axios) {
                    this.axios.cancel();
                    this.axios = null;
                }
            },

            loadedApiProviders() {
                this.$nextTick(() => {
                    window.setTimeout(() => {
                        this.$refs.searchCore.triggerSearch();
                    }, 250);
                });
            },

            resultSelected(result) {
                if (this.saving) {
                    return;
                }

                this.$logger.debug('Abo Create: Selected Result', result, 'plex-abos');

                this.alert.visible = false;
                this.saving        = true;
                this.$store.dispatch('activateLoader');

                this.axios = this.$axiosToken.source();

                this.$axios.post(
                    this.route('pwatcher.plex-abo.create'),
                    result,
                    {
                        cancelToken: this.axios.token,
                    },
                ).then((response) => {
                    if (this.noRedirect !== true) {
                        this.$router.push('/plex-abos');
                    } else {
                        this.$emit('pwatcher:plex-abos:abo-created', response.data);
                        this.resetSearches(true);
                    }
                }).catch((error) => {
                    const responseData = this.handleAxiosGlobalResponseErrors(error);

                    if (responseData.responseAvailable
                        && typeof responseData.data !== 'undefined'
                        && typeof responseData.data.message !== 'undefined'
                        && responseData.data.message !== '') {
                        this.alert.text    = responseData.data.message;
                        this.alert.visible = true;
                    } else {
                        this.$logger.error('Abo Create Error', [
                            error.response,
                            error.request,
                            error.message,
                        ], 'plex-abos');

                        this.$notifications.notify({
                            message: 'Beim Erzeugen deines Abos kam es zu einem Fehler. Bitte probier es erneut.',
                            type:    'error',
                        }, this);
                    }
                }).finally(() => {
                    this.saving = false;
                    this.axios  = null;
                    this.$store.dispatch('deactivateLoader');
                });
            },

            redirectToList() {
                if (this.noRedirect !== true) {
                    let route = this.comesFromRoute && this.comesFromRoute !== '' ? this.comesFromRoute : '/plex-abos';

                    if (this.$route.query && this.$route.query.redirect && this.$route.query.redirect !== '') {
                        route = this.$route.query.redirect;
                    }

                    this.$router.push(route);
                } else {
                    this.$emit('pwatcher:plex-abos:create-cancel');

                    this.resetSearches(true);
                }
            },

            resetSearches(resetInput) {
                if (typeof this.$refs.searchCore !== 'undefined' && typeof this.$refs.searchCore.triggerReset === 'object') {
                    this.$refs.searchCore.triggerReset();
                }

                if (typeof this.$refs.searchCorePlex !== 'undefined' && typeof this.$refs.searchCorePlex.resetSearch === 'object') {
                    this.$refs.searchCorePlex.resetSearch();
                }

                if (resetInput === true) {
                    this.resetInput();
                }
            },

            resetInput() {
                this.form.name = '';
                this.form.year = '';
            },
        },

        computed: {
            preFilteredForMedia() {
                return [
                    ...this.preloadedPlexAbos,
                    ...this.preFiltered,
                ];
            },

            preloadedPlexAbos() {
                let resultList = [];

                Object.keys(this.plexAbos).forEach((loadedResultSlug) => {
                    const slug = this.plexAbos[loadedResultSlug].typeWithSlugYear || null;

                    if (slug) {
                        resultList.push(slug);
                    }
                });

                return resultList;
            },
        },
    };
</script>
