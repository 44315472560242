<template>
    <div>
        <template v-if="! emptyResults">
            Folgende Ergebnisse wurden gefunden.
            Bitte wähle den Eintrag aus, der deinem Wunsch entspricht oder verfeinere die Suche, wenn nichts zutrifft.

            <div class="mt-1">
                Wenn du die Ergebnisse nicht mehr brauchst, kannst du sie hier ausblenden
                <v-btn color="info" x-small text @click="$emit('plex-search:result-hidden')">Ausblenden</v-btn>
            </div>

            <template>
                <div class="mt-4 mb-2">
                    Falls du auf Anhieb nicht den Eintrag findest, kannst du mit folgenden Filtern probieren, die Ergebnisse zu verkleinern.
                    Die Filter passen sich den Ergebnissen an
                </div>
                <v-row v-if="hasFilter">
                    <v-col cols="12" md="6" lg="4" v-if="hasTypeFilter && ! isGroupByType">
                        <div>Typ</div>
                        <div>
                            <v-btn-toggle
                                v-model="filters.type"
                                tile
                                dense
                                color="primary"
                                group
                            >
                                <v-btn :disabled="! hasSelectableTypeFilter" v-for="(name, type) in availableTypes" :value="type" :key="type" small>
                                    {{ name }}
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                    </v-col>

                    <v-col cols="12" md="6" lg="4" :offset-lg="! hasTypeFilter && ! isGroupByType ? 4 : 0" v-if="hasSubTypeFilter">
                        <div>Art</div>
                        <div>
                            <v-btn-toggle
                                v-model="filters.subType"
                                tile
                                dense
                                color="primary"
                                group
                            >
                                <v-btn :disabled="! hasSelectableSubTypeFilter" v-for="(name, type) in availableSubTypes" :value="type" :key="type" small>
                                    {{ name }}
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                    </v-col>
                </v-row>
            </template>

            <template v-if="groupedByType">
                <plex-search-results-grouped-by-type
                    :plex="filteredSubTypeResults"
                    :types="availableTypes"
                    :name="name"
                    :all-results-length="allResultsLength"
                    :force-large="forceLarge"
                    v-on:plex-search:result-selected="selectPlex"
                />
            </template>
            <plex-search-results-list
                v-else
                :plex="filteredSubTypeResults"
                :name="name"
                :all-results-length="allResultsLength"
                :force-large="forceLarge"
                v-on:plex-search:result-selected="selectPlex"
            />
        </template>
        <template v-else>
            Leider haben wir keine Ergebnisse zu deinen Eingaben gefunden. Versuche die Suche zu verfeinern
            - du kannst auch bei den Anbietern selbst nachschauen, wie der Eintrag dort hinterlegt ist.
        </template>
    </div>
</template>

<script>
    import collect                        from 'collect.js';
    import PlexSearchResultsList          from '@symfia/plex/views/PlexSearch/Results/PlexSearchResultsList';
    import PlexSearchResultsGroupedByType from '@symfia/plex/views/PlexSearch/Results/PlexSearchResultsGroupedByType';

    import {
        defaultActive as PlexDefaultSearchGroupByTypeDefault,
        flagName as PlexDefaultSearchGroupByTypeName,
    } from '../../Config/Flags/PlexDefaultSearchGroupByType';

    export default {
        components: {
            PlexSearchResultsList,
            PlexSearchResultsGroupedByType,
        },

        props: {
            plex: {
                type:     Object,
                required: true,
            },

            name: {
                type:    String,
                default: null,
            },

            forceLarge: {
                type:    Boolean,
                default: false,
            },
        },

        data: () => ({
            overrideLarge:          false,
            isGroupByType:          null,
            dontHideFilters:        true,
            dontRecalculateFilters: false,
            orderTypeFilters:       [
                'movie',
                'show',
            ],
            orderSubTypeFilters:    [
                'normal',
                'animation',
                'anime',
            ],
            filters:                {
                type:    '',
                subType: '',
            },
        }),

        methods: {
            selectPlex(selected) {
                this.$emit('plex-search:result-selected', selected);
            },

            typeFilterExist(type) {
                if (! type || type === '') {
                    return false;
                }

                if (typeof this.availableTypes === 'object' && typeof this.availableTypes[type] !== 'undefined') {
                    return true;
                }

                return false;
            },

            subTypeFilterExist(type) {
                if (! type || type === '') {
                    return false;
                }

                if (typeof this.availableSubTypes === 'object' && typeof this.availableSubTypes[type] !== 'undefined') {
                    return true;
                }

                return false;
            },
        },

        watch: {
            'filters.type': function () {
                if (! this.subTypeFilterExist(this.filters.subType)) {
                    this.filters.subType = '';
                }
            },
        },

        computed: {
            wantsGroupByType() {
                return this.isFeatureFlagActive(PlexDefaultSearchGroupByTypeName, PlexDefaultSearchGroupByTypeDefault);
            },

            groupedByType() {
                if (this.isGroupByType !== null) {
                    return this.isGroupByType;
                }

                return this.wantsGroupByType;
            },

            emptyResults() {
                return this.allResultsLength < 1;
            },

            wantsLargeNavigation() {
                return this.isFeatureFlagActive(PlexDefaultSearchLargeCardName, PlexDefaultSearchLargeCardDefault);
            },

            hasTypeFilter() {
                return Object.keys(this.availableTypes).length > 0;
            },

            hasSelectableTypeFilter() {
                return Object.keys(this.availableTypes).length > 1;
            },

            hasSubTypeFilter() {
                return Object.keys(this.availableSubTypes).length > 0;
            },

            hasSelectableSubTypeFilter() {
                return Object.keys(this.availableSubTypes).length > 1;
            },

            hasFilter() {
                return this.hasTypeFilter || this.hasSubTypeFilter;
            },

            availableTypes() {
                let types      = {};
                let FoundTypes = {};

                const filtered = this.allResults;

                filtered.forEach((item) => {
                    if (typeof FoundTypes[item.type] === 'undefined') {
                        FoundTypes[item.type] = item.typeTitle;
                    }
                });

                this.orderTypeFilters.forEach((api) => {
                    if (typeof FoundTypes[api] !== 'undefined') {
                        types[api] = FoundTypes[api];
                        delete FoundTypes[api];
                    }
                });

                types = {
                    ...types,
                    ...FoundTypes,
                };

                this.$logger.debug(
                    'Filters: Types',
                    types,
                    'plex-search',
                );

                return types;
            },

            availableSubTypes() {
                let subTypes      = {};
                let foundSubTypes = {};

                const filtered = this.dontRecalculateFilters ? this.allResults : this.filteredTypeResults;

                filtered.forEach((item) => {
                    if (typeof foundSubTypes[item.subType] === 'undefined' && item.subType !== null) {
                        foundSubTypes[item.subType] = item.subTypeTitle;
                    }
                });

                this.orderSubTypeFilters.forEach((api) => {
                    if (typeof foundSubTypes[api] !== 'undefined') {
                        subTypes[api] = foundSubTypes[api];
                        delete foundSubTypes[api];
                    }
                });

                subTypes = {
                    ...subTypes,
                    ...foundSubTypes,
                };

                this.$logger.debug(
                    'Filters: SubTypes',
                    subTypes,
                    'plex-search',
                );

                return subTypes;
            },

            filteredTypeResults() {
                let type    = this.filters.type;
                let results = this.allResults;

                if (type && type !== '') {
                    results = results.filter((result) => {
                        return result.type === type;
                    });
                }

                this.$logger.debug(
                    'Filtered type results',
                    {type, results},
                    'plex-search',
                );

                return results;
            },

            filteredSubTypeResults() {
                let subType = this.filters.subType;
                let results = this.filteredTypeResults;

                if (subType && subType !== '') {
                    results = results.filter((result) => {
                        return result.subType === subType;
                    });
                }

                this.$logger.debug(
                    'Filtered sub type results',
                    {subType, results},
                    'plex-search',
                );

                return results;
            },

            allResultsLength() {
                return this.allResults.length;
            },

            allResults() {
                let collection = collect(Object.values(this.plex));

                collection = collection.mapWithKeys(item => [item.typeAndSubtypeWithSlugYear, item]);

                const items = collection.all();

                this.$logger.debug('prepared results', items, 'plex-search');

                return Object.values(items);
            },
        },
    };
</script>
