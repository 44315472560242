<template>
    <div class="position-relative w-full flex cursor-pointer" @click="selectPlex">
        <media-card
            :poster="item.poster"
            :background="item.background"
            :banner="item.banner"
            :title="item.name"
            :small-title="usedTitle"
            :year="item.year"
            :desc="item.descriptionStripped"
            :small-desc="usedDesc"
            :left-sub-text="item.library"
            :small-debug-text="usedBreakpoint"
            :sub-text="allGenres"
            :small-sub-text="genreList"
            :small-card="smallCard"
        />
    </div>
</template>

<script>
    import MediaCard from '@symfia/library/Views/Cards/Media/MediaCard';

    export default {
        components: {
            MediaCard,
        },

        mounted() {

        },

        props: {
            item: {
                type:     Object,
                required: true,
            },

            smallCard: {
                type:    Boolean,
                default: false,
            },
        },

        data: () => ({}),

        methods: {
            selectPlex() {
                this.$emit('plex-search:result-selected', this.item);
            }
        },

        computed: {
            allGenres() {
                if (typeof this.item.details === 'undefined' || ! this.item.details || typeof this.item.details.genres === 'undefined' || ! Array.isArray(this.item.details.genres)) {
                    return '';
                }

                return this.item.details.genres.join(', ');
            },

            genreList() {
                if (typeof this.item.details === 'undefined' || ! this.item.details || typeof this.item.details.genres === 'undefined' || ! Array.isArray(this.item.details.genres)) {
                    return '';
                }

                let genres = this.item.details.genres.slice(0, 3);

                if (this.smallCard) {
                    if (! this.isLargeLaptopScreen() && ! this.isSmartphonePortrait()) {
                        genres = genres.slice(0, 2);
                    }
                }

                return genres.join(', ');
            },

            usedBreakpoint() {
                if (this.isDebugEnabled() === true && this.isLocalEnv() === true) {
                    if (this.smallCard) {
                        if (this.isTabletLandscape()) {
                            return 1;
                        }

                        if (this.isSmallLaptopScreen()) {
                            return 2;
                        }

                        if (! this.isLargeLaptopScreen()) {
                            return 3;
                        }

                        if (! this.isXlBreakpoint()) {
                            return 4;
                        }

                        if (this.is4kResolution()) {
                            return 5;
                        }
                    }

                    if (! this.hasPoster) {
                        return 6;
                    }

                    if (this.isSmartphonePortrait()) {
                        return 7;
                    }

                    if (this.isSmartphoneLandscape()) {
                        return 8;
                    }

                    if (this.isSmallLaptopScreen()) {
                        return 9;
                    }

                    if (! this.isLargeLaptopScreen()) {
                        return 10;
                    }

                    if (this.isXlBreakpoint()) {
                        return 11;
                    }

                    return 12;
                }

                return '';
            },

            usedDesc() {
                if (this.smallCard) {
                    if (this.isTabletLandscape()) {
                        return this.item.descriptionTruncatedStripped70;
                    }

                    if (this.isSmallLaptopScreen()) {
                        return this.item.descriptionTruncatedStripped100;
                    }

                    if (! this.isLargeLaptopScreen()) {
                        return this.item.descriptionTruncatedStripped75;
                    }

                    if (! this.isXlBreakpoint()) {
                        return this.item.descriptionTruncatedStripped75;
                    }

                    if (this.is4kResolution()) {
                        return this.item.descriptionTruncatedStripped110;
                    }
                }

                if (! this.hasPoster) {
                    return this.item.descriptionTruncatedStripped200;
                }

                if (this.isSmartphonePortrait()) {
                    return this.item.descriptionTruncatedStripped75;
                }

                if (this.isSmartphoneLandscape()) {
                    return this.item.descriptionTruncatedStripped175;
                }

                if (this.isSmallLaptopScreen()) {
                    return this.item.descriptionTruncatedStripped175;
                }

                if (! this.isLargeLaptopScreen()) {
                    return this.item.descriptionTruncatedStripped115;
                }

                if (this.isXlBreakpoint()) {
                    return this.item.descriptionTruncatedStripped130;
                }

                return this.item.descriptionTruncatedStripped150;
            },

            usedTitle() {
                if (this.smallCard) {
                    if (this.isTabletLandscape()) {
                        return this.item.nameTruncated40;
                    }

                    if (this.isSmallLaptopScreen()) {
                        return this.item.nameTruncated65;
                    }

                    if (! this.isLargeLaptopScreen()) {
                        return this.item.nameTruncated40;
                    }

                    if (! this.isXlBreakpoint()) {
                        return this.item.nameTruncated50;
                    }

                    if (this.is4kResolution()) {
                        return this.item.nameTruncated50;
                    }
                }

                if (! this.hasPoster) {
                    return this.item.name;
                }

                if (this.isSmartphonePortrait()) {
                    return this.item.nameTruncated40;
                }

                if (this.isSmartphoneLandscape()) {
                    return this.item.nameTruncated65;
                }

                if (this.isSmallLaptopScreen()) {
                    return this.item.nameTruncated65;
                }

                if (! this.isLargeLaptopScreen()) {
                    return this.item.nameTruncated50;
                }

                if (this.isXlBreakpoint()) {
                    return this.item.nameTruncated65;
                }

                return this.item.nameTruncated65;
            },

            hasPoster() {
                return this.item.poster && this.item.poster !== '';
            },

            typeAndSubType() {
                let type = this.item.typeTitle;

                if (this.item.subTypeTitle && this.item.subTypeTitle !== '') {
                    if (type !== '') {
                        type += ' / ';
                    }

                    type += this.item.subTypeTitle;
                }

                return type;
            },

            typeAndSubTypeWithLibrary() {
                let type = this.typeAndSubType;

                if (this.item.library && this.item.library !== '') {
                    if (type !== '') {
                        type += ' / ';
                    }

                    type += this.item.library;
                }

                return type;
            },
        },
    };
</script>
