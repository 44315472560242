<template>
    <div>
        <div>
            {{ paginationText }}
        </div>
        <v-row>
            <v-col
                :cols="xsCols"
                :sm="smCols"
                :md="mdCols"
                :lg="lgCols"
                :xl="xlCols"
                :style="style"
                class="flex items-stretch w-full"
                :key="index + '-'+result.id+'-' + result.typeAndSubtypeWithSlugYear"
                v-for="(result,index) in entriesForPage"
            >
                <plex-search-result-item
                    v-on:plex-search:result-selected="selectPlex"
                    :small-card="isLarge"
                    :item="result"
                />
            </v-col>
        </v-row>

        <v-pagination v-if="! hasOnlyOnePage" circle v-model="page" :length="availablePages"></v-pagination>
    </div>
</template>

<script>
    import PlexSearchResultItem from '@symfia/plex/views/PlexSearch/Results/PlexSearchResultItem';

    import {
        defaultActive as PlexDefaultSearchLargeCardDefault,
        flagName as PlexDefaultSearchLargeCardName,
    } from '../../../Config/Flags/PlexDefaultSearchLargeCard';

    export default {
        components: {
            PlexSearchResultItem,
        },

        props: {
            plex: {
                type:     Array,
                required: true,
            },

            name: {
                type:    String,
                default: null,
            },

            forceLarge: {
                type:    Boolean,
                default: false,
            },

            allResultsLength: {
                type:    Number,
                default: 0,
            },
        },

        data: () => ({
            page:          1,
            rows:          0,
            overrideLarge: false,
        }),

        methods: {
            selectPlex(selected) {
                this.$emit('plex-search:result-selected', selected);
            },
        },

        watch: {
            '$vuetify.breakpoint.name': function () {
                this.rows = 0;
            },

            isLarge: function () {
                this.rows = 0;
            },
        },

        computed: {
            style() {
                return 'max-height: 250px;';
            },

            wantsLargeNavigation() {
                return this.isFeatureFlagActive(PlexDefaultSearchLargeCardName, PlexDefaultSearchLargeCardDefault);
            },

            isLarge() {
                if (this.overrideLarge === true) {
                    return true;
                }

                if (this.wantsLargeNavigation) {
                    return true;
                }

                return this.forceLarge;
            },

            xsCols() {
                return 12;
            },

            smCols() {
                return this.isLarge ? 6 : 12;
            },

            mdCols() {
                return this.isLarge ? 4 : 6;
            },

            lgCols() {
                return this.isLarge ? 3 : 4;
            },

            xlCols() {
                if (this.isLarge && this.is4kResolution()) {
                    return 2;
                }

                return 3;
            },

            actualPage() {
                return this.page > 0 ? this.page : 1;
            },

            startAndEndResults() {
                const pageEntriesCount = this.entriesPerPage;
                const entriesCount     = this.resultsLength;
                let start              = 0;
                let end                = pageEntriesCount;

                if (this.actualPage > 1) {
                    start = ((this.actualPage - 1) * pageEntriesCount);
                    end   = this.actualPage * pageEntriesCount;

                    if (end > entriesCount) {
                        this.$logger.debug(
                            'Pagination: End bigger then result',
                            end,
                            'plex-search',
                        );

                        end = entriesCount;
                    }
                }

                return {start, end};
            },

            entriesForPage() {
                let results = this.plex;

                if (! this.hasOnlyOnePage) {
                    let {start, end} = this.startAndEndResults;

                    this.$logger.debug(
                        'Entries Data for slice',
                        {
                            start,
                            end,
                        },
                        'plex-search',
                    );

                    results = results.slice(start, end);
                }

                this.$logger.debug(
                    'Visible entries (with pagination support)',
                    results,
                    'plex-search',
                );

                return results;
            },

            entriesPerRow() {
                if (this.isXsBreakpoint()) {
                    return 12 / this.xsCols;
                }

                if (this.isSmBreakpoint()) {
                    return 12 / this.smCols;
                }

                if (this.isMdBreakpoint()) {
                    return 12 / this.mdCols;
                }

                if (this.isLgBreakpoint()) {
                    return 12 / this.lgCols;
                }

                return 12 / this.xlCols;
            },

            rowsPerPage() {
                if (this.rows && this.rows !== '' && this.rows > 0) {
                    return this.rows;
                }

                if (this.isXsBreakpoint()) {
                    return 3;
                }

                if (this.isSmBreakpoint()) {
                    return this.isLarge ? 3 : 3;
                }

                if (this.isMdBreakpoint()) {
                    return this.isLarge ? 3 : 3;
                }

                if (this.isLgBreakpoint()) {
                    return this.isLarge ? 3 : 3;
                }

                return this.isLarge ? 3 : 3;
            },

            entriesPerPage() {
                return this.entriesPerRow * this.rowsPerPage;
            },

            hasOnlyOnePage() {
                return this.availablePages <= 1;
            },

            availablePages() {
                if (this.resultsLength <= 0) {
                    return 1;
                }

                try {
                    let pages = Math.ceil(this.resultsLength / this.entriesPerPage);

                    this.$logger.debug(
                        'Pages count',
                        pages,
                        'plex-search',
                    );

                    return pages;
                } catch (e) {

                }

                return 1;
            },

            paginationText() {
                if (this.hasOnlyOnePage) {
                    return null;
                }

                const allLength      = this.allResultsLength;
                const filteredLength = this.resultsLength;
                let {start, end}     = this.startAndEndResults;

                let text = (start + 1) + ' - ' + end + ' von ' + filteredLength;

                if (allLength && filteredLength < allLength) {
                    text += ' (Gefiltert von ' + allLength + ')';
                }

                return text;
            },

            resultsLength() {
                return this.plex.length;
            },
        },
    };
</script>
