<template>
    <div class="transparent-tabs">
        <v-tabs v-if="hasMultipleTypes" show-arrows v-model="tab">
            <v-tab
                v-for="(typeData, type) in types"
                :key="'tab-'+type"
                v-if="dataForTypeExist(type)"
                class="feature-flag-tab"
            >
                {{ typeData }}
            </v-tab>

            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="(typeData, type) in types"
                    :key="'tab-items-'+type"
                    v-if="dataForTypeExist(type)"
                >
                    <v-card flat>
                        <v-card-text>
                            <plex-search-results-list
                                :plex="getPlexForType(type)"
                                v-on:plex-search:result-selected="selectPlex"
                                :year="year"
                                :all-results-length="allResultsLength"
                                :force-large="forceLarge"
                            />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
        <plex-search-results-list
            v-else
            :plex="plex"
            v-on:plex-search:result-selected="selectPlex"
            :name="name"
            :all-results-length="allResultsLength"
            :force-large="forceLarge"
        />
    </div>
</template>

<script>
    import PlexSearchResultsList from '@symfia/plex/views/PlexSearch/Results/PlexSearchResultsList';

    export default {
        components: {
            PlexSearchResultsList,
        },

        props: {
            plex: {
                type:     Array,
                required: true,
            },

            types: {
                type:     Object,
                required: true,
            },

            allResultsLength: {
                type:    Number,
                default: null,
            },

            name: {
                type:    String,
                default: null,
            },

            forceLarge: {
                type:    Boolean,
                default: false,
            },
        },

        data: () => ({
            tab: null,
        }),

        methods: {
            selectPlex(selected) {
                this.$emit('plex-search:result-selected', selected);
            },

            getPlexForType(type) {
                return this.plex.filter((result) => {
                    return result.type === type;
                });
            },

            dataForTypeExist(type) {
                const plex = this.getPlexForType(type);

                return plex.length > 0;
            },
        },

        computed: {
            hasMultipleTypes() {
                return Object.keys(this.types).length > 1;
            },
        },
    };
</script>
